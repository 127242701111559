import * as React from "react";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import {
  getPropertyRatingDetails,
  updatePropertyRatingValue,
} from "../../api/property";
import { toast } from "react-toastify";

export default function PropertyRating({ property_id }) {
  const [newVal, setNewVal] = React.useState(0);
  const [rating, setRating] = React.useState({
    my_rating: 0,
  });
  const updateRating = async (payload) => {
    try {
      if (rating?.my_rating !== newVal) {
        const update = await updatePropertyRatingValue(payload, property_id);
        if (update?.status === 200) {
          toast.success("Your rating has been updated successfully");
          getRatingValue();
        }
      }
    } catch (e) {
      toast.error(e?.message);
    }
  };
  const getRatingValue = async () => {
    try {
      const response = await getPropertyRatingDetails(property_id);
      const ratingObj = response?.data?.data;
      setRating(() => ({
        my_rating: ratingObj?.tenants[0]?.rating || 0,
      }));
    } catch (e) {
      toast.error(e?.response?.data?.message);
    }
  };
  React.useEffect(() => {
    getRatingValue();
  }, []);
  return (
    <>
      <div
        style={{
          // marginTop: "6px",
          display: "flex",
        }}
      >
        <span
          style={{
            fontWeight: "bolder",
            marginTop: "1px",
          }}
        >
          Rate
        </span>
        &nbsp;
        <Box
          sx={{
            "& > legend": { mt: 1 },
          }}
        >
          <Rating
            name="simple-controlled"
            value={rating?.my_rating}
            onChangeActive={(event, newValue) => {
              setNewVal(newValue);
            }}
            onClick={(e) => {
              e.preventDefault();
              updateRating({
                rating: newVal,
              });
            }}
          />
        </Box>
      </div>
    </>
  );
}
