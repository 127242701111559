import axiosInstance from "../utils/axios/auth";

const login = async (payload) => await axiosInstance.post('/auth/login', payload);
const getUser = async () => await axiosInstance.get('/user/me');
const recoverPassword = async (payload) => await axiosInstance.post('/auth/resend-token', payload);
const verifyOtp = async (payload) => await axiosInstance.post('/auth/verify-user', payload);
const forgetPasswordConfirm = async (payload) => await axiosInstance.post('/auth/forgot-password-confirm', payload)

export {
    login,
    getUser,
    recoverPassword,
    verifyOtp,
    forgetPasswordConfirm,
}