import React, { useRef } from "react";
import Slider from "react-slick";
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';

export function ProductSlider({ files }) {
    // const mainSliderRef = useRef(null);

    // const mainSliderSettings = {
    //     dots: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     infinite: true,
    //     autoplay: true,
    //     autoplaySpeed: 2000,
    // };

    // return (
    //     <div className="propertySliderSection">
    //         <Slider {...mainSliderSettings} className="mainSlider" ref={mainSliderRef}>
    //             {mainImages && mainImages?.map((image, index) => (
    //                 <div key={index}>
    //                     <img src={`${s3BaseUrl}${image?.location}`} alt={image.name} width="300" height="200" style={{ borderRadius: '5px', width: '100%' }} />
    //                 </div>
    //             ))}
    //         </Slider>
    //     </div>
    // );

    const sliderRef = useRef(null);

    const goToPrevSlide = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    };

    const goToNextSlide = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    };

    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <div>
            {files?.length > 0 && (
                <div>
                    <div className="slick-arrow custom-prev" onClick={goToPrevSlide}>
                        <ChevronLeftOutlinedIcon className="cursor-pointer " />
                    </div>
                    <Slider ref={sliderRef} {...settings}>
                        {files?.length > 0 && files.map((item, index) => (
                            <div key={index}>
                                {['jpg', 'jpeg', 'png', 'gif'].includes(item?.name?.split('.').pop().toString()) ?
                                    <img alt="" src={item.location} width={610} height={354} style={{ borderRadius: '5px', width: '100%' }} /> :

                                    <iframe title="PDF Viewer" src={`https://docs.google.com/viewer?url=${encodeURIComponent(item.location)}&embedded=true`} width={610} height={354} style={{ borderRadius: '5px', width: '100%' }} frameBorder="0" scrolling="no" />
                                }

                            </div>
                        ))}
                    </Slider>
                    <div className="slick-arrow custom-next" onClick={goToNextSlide}>
                        <ChevronRightOutlinedIcon style={{ cursor: 'pointer' }} />
                    </div>
                </div>
            )}
        </div>
    )
}
