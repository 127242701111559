import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import {
  Col, Modal, ModalBody, Row,
} from 'reactstrap';
import { ACTIONS } from '../../common/constant';
import { Spinner } from "reactstrap";

function ConfirmationModal({ show, onAcceptClick, onCloseClick, action, isDisabled = false, text="" }) {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!show) {
      setIsLoading(false)
    }
  }, [show])

  const handleClick = () => {
    setIsLoading(true);
    onAcceptClick();
  }

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered>
      <ModalBody className="py-3 px-5">
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <i
                className="mdi mdi-alert-circle-outline"
                style={{ fontSize: '9em', color: 'orange' }}
              />
              {
              action === ACTIONS.CREATE
                  ? <><h4>Are you sure you want to create it?</h4> {text && <p style={{ color: '#FAA61B', fontSize: '14px' }}>{text}</p>}</>
              : action === ACTIONS.EDIT 
              ? <h4>Are you sure you want to update it?</h4>
              : action === ACTIONS.DELETE 
              ? <h4>Are you sure you want to delete it?</h4>
                      : <h4>{`Are you sure, you want to ${text ? text : 'do it'}?`}</h4>
              }
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3">
              <button
                disabled={isDisabled}
                type="button"
                className="btn btn-success btn-lg ms-2"
                onClick={handleClick}
              >
                {isLoading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-1"
                  />
                )}
                <span className="visually-hidden"> Loading...</span>
                Yes
              </button>
              <button
                disabled={isDisabled}
                type="button"
                className="btn btn-danger btn-lg ms-2"
                onClick={onCloseClick}
              >
                No
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
}

ConfirmationModal.propTypes = {
  onCloseClick: PropTypes.func,
  onAcceptClick: PropTypes.func,
  show: PropTypes.bool,
  action: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export default ConfirmationModal;
