import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

export default function Variants({ stackSpacing = 1, skeletonData=[] }) {
  return (
    <Stack spacing={stackSpacing}>
      {skeletonData?.map(({variant, width=20, height=20, style={}}, id) => (
        <>
          <Skeleton variant={variant||'rectangular'} width={width||210} height={height||40} sx={{ ...style }} />
        </>
      ))}
    </Stack>
  );
}
