import React, { useEffect } from 'react';

import {
  Row, Col, CardBody, Card, Container, Form, Input, Label, FormFeedback,
} from 'reactstrap';

import { loginApi, userDetailsApi } from '../../api/user/login';

// Formik Validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

// import images
import logoDark from '../../assets/images/logo1.png';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { storeUserDetails } from '../../store/auth';
import { PORTAL } from '../../common/constant';

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  document.title = ' Login | DStayZ';

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: '',
      contact_number: ''
    },
    validationSchema: Yup.object({
      password: Yup.string().required('Please Enter Your Password'),
      contact_number: Yup.string().matches(/^[0-9]{10}$/, 'Contact number must be 10 digits').required('Contact number is required')
    }),
    onSubmit: async (values) => {
      try {
        const token = await loginApi({
          portal: PORTAL.TENANT_PORTAL,
          password: values.password,
          contact_number: values.contact_number.toString(),
          isEmail: false
        })
        let userDetails
        if (token) {
          localStorage.setItem('token-tenant', token)
          userDetails = await userDetailsApi()
          dispatch(storeUserDetails({ user: userDetails }))
          if (userDetails?.user?.is_password_reset) {
            navigate("/dashboard")
          } else {
            navigate("/reset-password")
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  });

  useEffect(() => {
    document.body.className = 'authentication-bg';
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = '';
    };
  });

  return (
    <div className="account-pages my-5 pt-sm-5">
      <Container>

        <Row className="align-items-center justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card>
              <Row>
                <Col lg={12}>
                  <div className="text-center">
                    <Link to="/" className="mt-2 d-block auth-logo">
                      <img src={logoDark} alt="" height="48" className="logo logo-dark" />
                    </Link>
                  </div>
                </Col>
              </Row>

              <CardBody>
                <div className="text-center">
                  <h5 className="text-primary">Welcome to Dstayz Tenant's Portal!</h5>
                  <p className="text-muted">Sign in to continue to DStayZ.</p>
                </div>
                <div className="p-2 mt-4">
                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >

                    <div className="mb-3">
                      <Label className="form-label">Phone Number</Label>
                      <Input
                        name="contact_number"
                        className="form-control"
                        placeholder="Enter Phone Number"
                        onChange={validation.handleChange}
                        type="text"
                        onBlur={validation.handleBlur}
                        value={validation.values.contact_number || ''}
                        invalid={
                          !!(validation.touched.contact_number && validation.errors.contact_number)
                        }
                      />
                      {validation.touched.contact_number && validation.errors.contact_number && (
                        <FormFeedback type="invalid">{validation.errors.contact_number}</FormFeedback>
                      )}
                    </div>

                    <div className="mb-3">
                      <div className="float-end">
                        <Link to="/recover-password" className="text-muted">Forgot password?</Link>
                      </div>
                      <Label className="form-label">Password</Label>
                      <Input
                        name="password"
                        type="password"
                        placeholder="Enter Password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.password || ''}
                        invalid={
                          !!(validation.touched.password && validation.errors.password)
                        }
                      />
                      {validation.touched.password && validation.errors.password ? (
                        <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customControlInline"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customControlInline"
                      >
                        Remember me
                      </label>
                    </div>

                    <div className="mt-3 text-end">
                      <button
                        className="btn btn-primary w-sm waves-effect waves-light"
                        type="submit"
                      >
                        Log In
                      </button>
                    </div>

                    <div className="mt-4 text-center">
                      <p className="mb-0">
                        Don&apos;t have an account ?
                        <a href="/pages-register" className="fw-medium text-primary"> Signup now </a>
                      </p>
                    </div>
                  </Form>
                </div>

              </CardBody>
            </Card>

            <div className="mt-5 text-center">
              <p>
                ©
                {' '}
                {new Date().getFullYear()}
                {' '}
                Minible. Crafted with
                {' '}
                <i className="mdi mdi-heart text-danger" />
                {' '}
                by Themesbrand
              </p>
            </div>

          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Login;
