import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col, Input } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import useChannel from '../../../utils/pusher/hooks';
import { NOTIFICATION_EVENTS } from '../../../constants/variables';
import { getAllNotificationList, getAllUnreadNotificationCount, markAsReadNotificationApi } from '../../../api/notification';
import { useSelector } from "react-redux";
import moment from "moment";
import { CircularProgress } from '@mui/material';
import { NoDataFound } from '../../Common/NoDataFound';
import ReactQuill from 'react-quill';
import NoDataIcon from '../../../assets/images/no-data.png'

const Notification = ({ subject, body }) => {
  return (
    <div className="notification">
      <div className="notification-icon">
        <i className="fas fa-bell"></i>
      </div>
      <div className="notification-content">
        <h3 className="notification-subject">{subject}</h3>
        <p className="notification-body">
        <ReactQuill
          value={body?.replace(/(<([^>]+)>)/gi, "")}
          readOnly={true}
          theme={'bubble'}
        />
        </p>
      </div>
    </div>
  );
};

const NotificationDropdown = (props) => {
  const [menu, setMenu] = useState(false);
  const [isMounted, setMounted] = useState(true)
  const currentUserId = useSelector(
    (state) => state?.AuthLogin?.auth?.user?.id
  );

  const callPusher = (data) => {
    if (data) {
      fetchUnreadNotificationCount()
      toast(<Notification subject={data.subject} body={data.body}/>, {
        position: 'top-center'
      })
    }
  }

  Object.values(NOTIFICATION_EVENTS).forEach((event) => (
    useChannel(null, event.event, callPusher)
  ))

  useEffect(() => {
    if(isMounted) {
    fetchUnreadNotificationCount()
    callPusher()
    setMounted(false);
  }
  }, [])

  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0)
  const fetchUnreadNotificationCount = async () => {
    try {
      const res = await getAllUnreadNotificationCount()
      setUnreadNotificationCount(res.data.data)
    } catch (e) {
      console.error(e)
    }
  }

  const [notificationList, setNotificationList] = useState([])
  const fetchNotificationList = async () => {
    try {
      setLoading(true);
      const params = {
        filter_fields: ['receiver_id'],
        filter_inputs: [currentUserId],
        page: currentPage
      }
      const res = await getAllNotificationList(params)
      setNotificationList([...notificationList, ...res.data.data.data]);
      setCurrentPage(prevPage => prevPage + 1);
      setPaginationMeta(res.data.data.meta)
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (menu) {
      fetchNotificationList()
      fetchUnreadNotificationCount()
    }
  }, [menu])

  const [currentPage, setCurrentPage] = useState(1);
  const [paginationMeta, setPaginationMeta] = useState(null);
  const [loading, setLoading] = useState(false);
  const observer = useRef();
  const lastNotificationRef = useRef(null)
  const [selectedItems, setSelectedItems] = useState([])
  const [selectedMulti, setSelectedMulti] = useState(false)
  const navigate = useNavigate();

  useEffect(() => {
    const handleIntersection = (entries) => {
      const target = entries[0];
      if (target.isIntersecting && !loading) {
        fetchNotificationList();
      }
    };

    observer.current = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    });

    if (lastNotificationRef.current && paginationMeta.next) {
      observer.current.observe(lastNotificationRef.current);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [currentPage]);

  const markAsReadNotification = async (ids, isRead) => {
    try {
      if (!isRead) {
        await markAsReadNotificationApi({ids})
        await fetchUnreadNotificationCount()
      }
      setMenu(!menu)
      setSelectedMulti(false)
      toast.success('Notification(s) marked as read successfully.')
    } catch (e) {
      toast.error('Something went wrong.')
    }
  }

  const getNotificationURL = (event) => {
    if(event)
    return NOTIFICATION_EVENTS[Object.keys(NOTIFICATION_EVENTS).find(key => NOTIFICATION_EVENTS[key].event === event)]?.url;
  }
  
  return (
    <Dropdown
      isOpen={menu}
      toggle={() => {
        setMenu(!menu)
        setNotificationList([]);
        setCurrentPage(1);
        setPaginationMeta(null)
        setSelectedItems([])
        setSelectedMulti(false)
      }}
      className="dropdown d-inline-block"
      tag="li"
    >
      <DropdownToggle
        className="btn header-item noti-icon waves-effect"
        tag="button"
        id="page-header-notifications-dropdown"
      >
        <i className="uil-bell" />
        {unreadNotificationCount > 0 ? (<span className="badge bg-danger rounded-pill">{unreadNotificationCount}</span>) : null}
      </DropdownToggle>

      <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
        <div className="p-3">
          <Row className="align-items-center">
            <Col>
              <div className='d-flex'>
                {notificationList.length > 0 &&
                  <div className="form-check">
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="formrow-customCheck"
                    checked={selectedMulti}
                    onClick={() => {
                      setSelectedMulti((prev) => !prev)
                      if (!selectedMulti) {
                        setSelectedItems(() => {
                          return [...notificationList.map(item => item.id)]
                        })
                      } else {
                        setSelectedItems([])
                      }
                    }}
                  />
                </div>}
                <h6 className="mx-1 font-size-16">
                  {' '}
                  {props.t('Notifications')}
                  {' '}
                </h6>
              </div>
            </Col>
            {notificationList.length > 0 && selectedItems.length > 0 &&
              <div className="col-auto">
              <Link className="small" onClick={() => markAsReadNotification(selectedItems, false)}>
                Mark {selectedMulti ? 'all' : 'selected'} as read
              </Link>
            </div>
            }
          </Row>
        </div>

        <SimpleBar style={{ 'max-height': '230px' }} >
          {notificationList.length > 0 && notificationList.map((notification, index) => {
            return (
              <span
                className='text-dark notification-item'
                style={{cursor: 'pointer'}}
                key={index}
                ref={notificationList.length === index + 1 ? lastNotificationRef : null}
                onClick={() => {
                  if (notification.is_read === false)
                  markAsReadNotification([notification.id], notification?.is_read)

                  if(notification)
                  navigate(getNotificationURL(notification.notification.content.event))
                }}
              >
                <div className={`d-flex my-1 align-items-start ${!notification?.is_read && 'notification-active'}`}>
                <div className="form-check">
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id={`checkbox-${notification.id}`}
                    checked={selectedItems.includes(notification.id)}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedItems((prev) => {
                        if (selectedItems.includes(notification.id)) {
                          return prev.filter(item => item !== notification.id)
                        } else {
                          return [...prev, notification.id]
                        }
                      })
                    }}
                  />
                </div>
                  <div className="flex-1">
                    <h6 className="mt-0 mb-1">{notification?.notification?.content?.subject}</h6>
                    <div className="font-size-12 text-muted">
                    <ReactQuill
                      value={notification?.notification?.content?.body?.replace(/(<([^>]+)>)/gi, "")}
                      readOnly={true}
                      theme={'bubble'}
                    />
                      <p className="mb-0">
                        <i className="mdi mdi-clock-outline me-1" />
                        {moment(notification?.created_at).fromNow()}
                      </p>
                    </div>
                  </div>
                </div>
              </span>
            )
          })}
          {notificationList?.length === 0 && loading && (
            <div style={{ textAlign: 'center' }}>
              <CircularProgress />
            </div>
          )}

          {notificationList?.length === 0 && !loading && (
            <div style={{ textAlign: 'center' }}>
              <NoDataFound text='No Notifications to show' style={{ width: '50px', height: '50px' }} icon={NoDataIcon} />
            </div>
          )}
        </SimpleBar>
        <div className="p-2 border-top d-grid">
          <Link
            className="btn btn-sm btn-link font-size-14 text-center"
            to="/notifications"
            onClick={() => setMenu(!menu)}
          >
            <i className="uil-arrow-circle-right me-1" />
            {' '}
            {props.t('View all')}
            {' '}
          </Link>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
}

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any,
};
