import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import withRouter from '../../components/Common/withRouter';

import { logoutUser, removeUserDetails } from '../../store/actions';
import ConfirmationModal from '../../components/Common/ConfirmationModal';

function Logout() {
  document.title = ' Logout | DStayZ';
  const [isConfirmModal, setIsConfirmModal] = useState(true)
  const handleCloseModal = () => setIsConfirmModal(false);

  const history = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    localStorage.removeItem('token-tenant')
    dispatch(removeUserDetails())
    dispatch(logoutUser(history));
  }

  return (
    <div>
      <ConfirmationModal
        text={'Logout'}
        show={isConfirmModal}
        onCloseClick={() => {
          handleCloseModal()
          history(-1)
        }}
        onAcceptClick={handleLogout}
      />
    </div>
  );
}

Logout.propTypes = {
  history: PropTypes.object,
  logoutUser: PropTypes.func,
};

export default withRouter(connect(null, { logoutUser })(Logout));
