import React from 'react';
import { Navigate } from 'react-router-dom';
import PagesMaintenance from '../pages/Utility/pages-maintenance';
import PagesComingsoon from '../pages/Utility/pages-comingsoon';
import Pages404 from '../pages/Utility/pages-404';
import Pages500 from '../pages/Utility/pages-500';
import Login from '../pages/Authentication/Login';
import Logout from '../pages/Authentication/Logout';
import Register from '../pages/Authentication/Register';

//  // Inner Authentication
// import Login1 from '../pages/AuthenticationInner/Login';
// import Register1 from '../pages/AuthenticationInner/Register';
// import Recoverpw from '../pages/AuthenticationInner/Recoverpw';
// import LockScreen from '../pages/AuthenticationInner/auth-lock-screen';

// Profile
import UserProfile from '../pages/Authentication/user-profile';
import RecoverPassword from '../pages/Authentication/RecoverPassword';

import TenantDashboard from '../pages/Dashboard/tenantDashboard';
import ComplainTicket from '../pages/ComplainTicket';
import RentPaymentHistory from '../pages/RentPaymentHistory';
import PGVacantRequest from '../pages/PGVacantRequest';
import Message from '../pages/Message';
import Notifications from '../pages/Notifications';

const authRoutes = [
  { path: '/dashboard', component: <TenantDashboard /> },
  { path: '/profile', component: <UserProfile /> },
  // {
  //   path: '/',
  //   exact: true,
  //   component: <Navigate to="/dashboard" />,
  // },
  { path: '*', component: <Navigate to='/dashboard' /> },
  { path: '/complain-ticket', component: <ComplainTicket /> },
  { path: '/rent-payment-history', component: <RentPaymentHistory /> },
  { path: '/pg-vacant-request', component: <PGVacantRequest /> },
  { path: '/message', component: <Message /> },
  { path: '/notifications', component: <Notifications /> },
];

const publicRoutes = [
  { path: '/logout', component: <Logout /> },
  { path: '/login', component: <Login /> },
  { path: '/recover-password', component: <RecoverPassword /> },
  { path: '/register', component: <Register /> },

  { path: '/pages-maintenance', component: <PagesMaintenance /> },
  { path: '/pages-comingsoon', component: <PagesComingsoon /> },
  { path: '/pages-404', component: <Pages404 /> },
  { path: '/pages-500', component: <Pages500 /> },

  // Authentication Inner
  // { path: '/pages-login', component: <Login1 /> },
  // { path: '/pages-register', component: <Register1 /> },
  // { path: '/page-recoverpw', component: <Recoverpw /> },
  // { path: '/auth-lock-screen', component: <LockScreen /> },
];

export { publicRoutes, authRoutes };
