import { getUser, login } from '../user'
import { toast } from "react-toastify";

const loginApi = async (values) => {
  try {
    const response = await login(values)
    if (response.status === 200) {
      toast.success(response.data.message);
      return response.data.data.token
    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    toast.error(error.response.data.message)
    return Promise.reject(error)
  }
}

const userDetailsApi = async () => {
  try {
    const response = await getUser()
    return response.data.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export {
  loginApi,
  userDetailsApi
}