import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

// i18n
import { withTranslation } from 'react-i18next';
// Redux
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import withRouter from '../../Common/withRouter';

// users
import { useProfile } from '../../Hooks/UserHooks';
import { getS3BaseUrl, ucFirst } from '../../../helpers/string_helper';
import ViewProfileDrawer from './viewProfileDrawer';

function ProfileMenu(props) {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const { user } = useProfile()
  const s3Url = getS3BaseUrl()

  const [username, setusername] = useState('Admin');
  const [isProfileViewer, setIsProfileViewer] = useState(false);
  const toggleViewProfileDetail = () => {
    setIsProfileViewer(!isProfileViewer);
  };

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
        const obj = JSON.parse(localStorage.getItem('authUser'));
        setusername(obj.displayName);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === 'fake'
        || process.env.REACT_APP_DEFAULTAUTH === 'jwt'
      ) {
        const obj = JSON.parse(localStorage.getItem('authUser'));
        if (obj.username) {
          setusername(obj.username);
        } else {
          setusername(obj.name);
        }
      }
    }
  }, [props.success]);

  const getInitialLetter = (name) => {
    const nameArray = name.split(' ')
    let initial = ''
    nameArray.forEach((item) => {
      initial = initial.concat(ucFirst(item.charAt(0)))
    })

    return initial
  }


  return (
    <div>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
          style={{ display: 'flex', alignItems: 'center'}}
        >
          {user.profile_photo ? (
            <img
              className="initial_letter"
              src={`${s3Url}${user.profile_photo}`}
              alt="Header Avatar"
              style={{ marginRight: '8px'}}
            />
          ) : (
            <span className='initial_letter'> {getInitialLetter(user.name)} </span>
          )}

          <span className='d-none d-xl-inline-block font-size-13 fw-medium' style={{ textAlign: 'left'}}>{user.name}
            <div className='fw-small font-size-11' style={{ color: 'gray' }}>{user.role.role}</div>
          </span>
          {' '}
          <i className="uil-angle-down d-none d-xl-inline-block font-size-15" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="#" onClick={toggleViewProfileDetail}>
            {' '}
            <i className="uil uil-user-circle font-size-18 align-middle text-muted me-1" />
            {props.t('View Profile')}
            {' '}
          </DropdownItem>
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted" />
            <span>{props.t('Logout')}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
      {isProfileViewer && (
        <ViewProfileDrawer
          isOpen={isProfileViewer}
          toggleDrawer={toggleViewProfileDetail}
        />
      )}
    </div>
  );
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu)),
);
