import { createContext, useEffect, useMemo, useState } from "react";
import { getTenantDetails } from "../../api/tenant";
import { useProfile } from "../../components/Hooks/UserHooks";

export const TenantIdContext = createContext(null);

export const TenantContext = (props) => {
  const { user } = useProfile();
  const [tenant, setTenant] = useState({});
  const fetchTenantDetails = async () => {
    try {
      const response = (await getTenantDetails(user?.id)).data.data;
      setTenant((old)=>({...old,id:response?.user?.tenant?.id}));
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(()=>{
    fetchTenantDetails()
  },[])

  const tenantValue = useMemo(() => ({ tenant, setTenant }), [tenant?.id]);
  return (
    <>
      <TenantIdContext.Provider value={tenantValue}>
        {props.children}
      </TenantIdContext.Provider>
    </>
  );
};
