import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { CancelOutlined, Info } from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { TextField, Popover, Typography } from '@mui/material';
import { Button } from 'reactstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { pgVacantRequestCreate } from '../../api/vacant_request';
import { AsterikLabel } from '../Common/AsterikLabel';
import ConfirmationModal from '../Common/ConfirmationModal';
import { ACTIONS } from '../../common/constant';
import { Row } from 'reactstrap';

export default function GenerateLeavingRequest({ open, onClose, noticePeriods, bedDetails, refresh }) {
    const [isConfirmModal, setIsConfirmModal] = useState(false);
    const noticePeriodDate = dayjs().add(noticePeriods.value, noticePeriods.unit)
    const validationDate = dayjs(noticePeriodDate).subtract(1, 'days');
    const handleOpenConfimationModal = () => setIsConfirmModal(true);
    const handleCloseConfimationModal = () => setIsConfirmModal(false);
    const [isModal, setIsModal] = useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openInfo = Boolean(anchorEl);

    const handleClickInfo = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseInfo = () => {
        setAnchorEl(null);
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            leaving_date: "",
            leave_reason: ''
        },
        validateOnMount: true,
        validationSchema: Yup.object({
            leaving_date: Yup.date().typeError("Given date is invalid type")
                .min(dayjs(validationDate), `Your Date of Leaving should be equal to or after ${dayjs(validationDate).format('DD/MM/YYYY')} as Notice Period is ${noticePeriods.value} ${noticePeriods.unit}. Please contact admin to leave the pg before the specified date.`)
                .required('Please enter leaving date'),
            leave_reason: Yup.string().required('Please enter leaving reason'),
            bank_detail: Yup.object().shape({
                account_holder_name: Yup.string()
                    .strict(true)
                    .trim('Must not include leading and trailing spaces')
                    .max(100, 'Too Long!')
                    .required('Please enter account holder name'),
                account_number: Yup.string()
                    .matches(/^\d{9,18}$/, 'Invalid account number')
                    .required('Please enter account number'),
                bank_name: Yup.string()
                    .strict(true)
                    .trim('Must not include leading and trailing spaces')
                    .max(100, 'Too Long!')
                    .required('Please enter bank name'),
                ifsc: Yup.string()
                    .strict(true)
                    .trim('Must not include leading and trailing spaces')
                    .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, 'Invalid IFSC code')
                    .required('Please enter ifsc code'),
            }),
        }),
    });

    const vacantRequestCreateForTenant = async () => {
        try {
            const payload = {
                leaving_date: formik.values.leaving_date,
                ...(formik.values.leave_reason && {
                    leave_reason: formik.values.leave_reason
                }),
                tenant_id: bedDetails.tenant_id,
                bed_id: bedDetails.bed_id,
                ...(formik.values.bank_detail && {
                    bank_detail: {
                        ...(formik.values.bank_detail.account_holder_name && {
                            account_holder_name: formik.values.bank_detail.account_holder_name,
                        }),
                        ...(formik.values.bank_detail.account_number && {
                            account_number: formik.values.bank_detail.account_number,
                        }),
                        ...(formik.values.bank_detail.ifsc && {
                            ifsc: formik.values.bank_detail.ifsc,
                        }),
                        ...(formik.values.bank_detail.bank_name && {
                            bank_name: formik.values.bank_detail.bank_name,
                        }),
                    },
                }),
            }

            const response = await pgVacantRequestCreate(payload)
            if (response.status === 200) {
                toast.success(response.data.message);
                refresh()
                onClose()
            } else {
                toast.error(response.data.message)
            }
        } catch (err) {
            toast.error(err.response.data.message);
        }
    }

    return (
        <React.Fragment>
            {isModal ? (
                <Dialog
                    open={open}
                    onClose={onClose}
                    disableScrollLock={true}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                        sx: { width: "560px", borderRadius: "10px", overflow: "hidden" },
                    }}
                    className='ticket_detail_modal'
                >
                    <DialogTitle className='ticket_detail_header d-flex justify-content-between'>
                        <div>
                            <small>Generate Leaving Request</small>
                        </div>
                        <CancelOutlined onClick={onClose} style={{ cursor: "pointer", marginTop: "5px" }} />
                    </DialogTitle>
                    <DialogContent>
                        <div className='d-flex justify-content-center mt-2 mb-2 text-bold' style={{ fontSize: '18px' }}> Notice Period is {noticePeriods?.value} {noticePeriods?.unit}</div>
                        <div className='d-flex justify-content-center mt-2'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker
                                        label={<div>Leaving Date <AsterikLabel /></div>}
                                        name="leaving_date"
                                        format='DD/MM/YYYY'
                                        value={formik?.values?.leaving_date ? formik?.values?.leaving_date : null}
                                        slotProps={{
                                            textField: {
                                                clearable: true,
                                                size: 'small', variant: "outlined", onBlur: () => {
                                                    if (!formik.touched?.leaving_date) {
                                                        formik.setFieldTouched("leaving_date", true, false);
                                                    }
                                                },
                                                onClear: () => {
                                                    formik.setFieldValue(
                                                        "leaving_date",
                                                        ""
                                                    );
                                                },
                                            }
                                        }}
                                        className='w-100'
                                        onChange={(e) => {
                                            formik.setFieldValue("leaving_date", e ? dayjs(e) : "");
                                            if (!formik.touched?.leaving_date) {
                                                formik.setFieldTouched("leaving_date", true, false);
                                            }
                                        }}
                                        required
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </div>
                        <div className='d-flex justify-content-center mb-3 mt-1'>
                            {formik.touched.leaving_date && formik.errors.leaving_date && (
                                <span className="text-danger">{formik.errors.leaving_date}</span>
                            )}
                        </div>
                        <div className='reason_box'>
                            {/* <h5>Leaving Reasons</h5> */}
                            <TextField
                                fullWidth
                                id="outlined-multiline-static txtAddress"
                                label={<div>Leaving Reasons <AsterikLabel /></div>}
                                placeholder='Enter your leaving reason'
                                name="leave_reason"
                                multiline
                                rows="3"
                                className="form-control"
                                value={formik.values.leave_reason}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <div className='d-flex mb-3 mt-1'>
                                {formik.touched.leave_reason && formik.errors.leave_reason && (
                                    <span className="text-danger">{formik.errors.leave_reason}</span>
                                )}
                            </div>
                            {/* Quill Editor */}
                            {/* <ReactQuill value={editorHtml} name="" onChange={handleChange} placeholder='Enter your leaving reason' /> */}
                        </div>
                        <Row>
                            <div className='col-12 mb-1'>
                                <div className='bankAccountDet'>
                                    <h5>Bank Account Details
                                        <Info onMouseEnter={handleClickInfo} className='ms-1' />
                                        <Popover
                                            className='popup_block'
                                            id={bedDetails?.bed_id}
                                            open={openInfo}
                                            anchorEl={anchorEl}
                                            onClose={handleCloseInfo}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                        >
                                            <Typography sx={{ p: 1 }} className='popContent'>
                                                <p>Please provide your bank account  details to receive the caution money (after deduction of the pending payments if any)</p>
                                            </Typography>
                                        </Popover>
                                    </h5>
                                </div>
                            </div>
                            <div className='col-sm-6 mb-3'>
                                <TextField
                                    size='small'
                                    className='form-control'
                                    type='text'
                                    name='bank_detail.account_holder_name'
                                    label={<div>Account Holder Name <AsterikLabel /></div>}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values?.bank_detail?.account_holder_name || ''}
                                />
                                <div className='d-flex mt-1'>
                                    {formik.touched?.bank_detail?.account_holder_name && formik.errors.bank_detail?.account_holder_name && (
                                        <span className="text-danger">{formik.errors.bank_detail?.account_holder_name}</span>
                                    )}
                                </div>
                            </div>
                            <div className='col-sm-6 mb-3'>
                                <TextField
                                    size='small'
                                    className='form-control'
                                    type='text'
                                    name='bank_detail.account_number'
                                    label={<div>Account Number <AsterikLabel /></div>}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values?.bank_detail?.account_number || ''}
                                />
                                <div className='d-flex mt-1'>
                                    {formik.touched?.bank_detail?.account_number && formik.errors.bank_detail?.account_number && (
                                        <span className="text-danger">{formik.errors.bank_detail?.account_number}</span>
                                    )}
                                </div>
                            </div>
                            <div className='col-sm-6 mb-4'>
                                <TextField
                                    size='small'
                                    className='form-control'
                                    type='text'
                                    name='bank_detail.ifsc'
                                    label={<div>IFSC <AsterikLabel /></div>}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values?.bank_detail?.ifsc || ''}
                                />
                                <div className='d-flex mt-1'>
                                    {formik.touched?.bank_detail?.ifsc && formik.errors.bank_detail?.ifsc && (
                                        <span className="text-danger">{formik.errors.bank_detail?.ifsc}</span>
                                    )}
                                </div>
                            </div>
                            <div className='col-sm-6 mb-4'>
                                <TextField
                                    size='small'
                                    className='form-control'
                                    type='text'
                                    name='bank_detail.bank_name'
                                    label={<div>Bank Name <AsterikLabel /></div>}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values?.bank_detail?.bank_name || ''}
                                />
                                <div className='d-flex mt-1'>
                                    {formik.touched?.bank_detail?.bank_name && formik.errors.bank_detail?.bank_name && (
                                        <span className="text-danger">{formik.errors.bank_detail?.bank_name}</span>
                                    )}
                                </div>
                            </div>
                        </Row>
                        <div className="gen_leave_add_btn text-center">
                            <Button className="yellow_gradient_btn pay_full_amt mt-3" disabled={!formik.isValid} onClick={() => {
                                setIsModal(false)
                                handleOpenConfimationModal()
                            }} style={formik.isValid ? { cursor: 'pointer' } : { cursor: 'not-allowed', backgroundColor: 'gray' }}> Save </Button>
                        </div>
                    </DialogContent>
                </Dialog>
            ) : (
                <ConfirmationModal
                    action={ACTIONS.CREATE}
                    show={isConfirmModal}
                    text="Please check carefully the bank account details before submitting the PG vacant request."
                    onCloseClick={() => {
                        setIsModal(true)
                        handleCloseConfimationModal()
                    }}
                    onAcceptClick={vacantRequestCreateForTenant}
                />
            )}
        </React.Fragment>
    );
}
