import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import image1 from "../../assets/images/appartment.png";

export default function SingleTicket({ open, onClose }) {
    const ticketDetails = [
        { label: 'Date', value: '12.10.23', className: 'item1' },
        { label: 'Contact Number', value: '9876543210', className: 'item2' },
        { label: 'Residence', value: 'Maa Tara Bhawan, ABC Road, Kol - 700156', className: 'item3' },
        { label: 'Resolved By', value: 'Anna Adame', className: 'item4' },
        { label: 'Resolved Date', value: '18.12.23', className: 'item5' }
    ];

    const handleCloseDialog = (agree) => {
        // Call the onClose function passed from the parent component
        onClose();
    };

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    sx: { width: "560px", borderRadius: "10px", overflow: "hidden" },
                }}
                className='ticket_detail_modal'
            >
                <DialogTitle className='ticket_detail_header d-flex justify-content-between'>
                    <div>
                        <small>Details</small>
                        <h4 className='ticket_id'>Ticket Id: <span>123456</span></h4>
                    </div>
                    <CancelOutlinedIcon onClick={onClose} style={{ cursor: "pointer", marginTop: "5px" }} />
                </DialogTitle>
                <DialogContent>
                    <ul className='two_one_track list-inline'>
                        {ticketDetails.map((detail, index) => (
                            <li className={`grid_item ${detail.className}`} key={index}>
                                <small>{detail.label}</small>
                                <h5>{detail.value}</h5>
                            </li>
                        ))}
                    </ul>
                    <div className='complain_details'>
                        <h5>Complain Details</h5>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec urna odio, porttitor non lorem at, dignissim porttitor nunc. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nam in tristique odio.</p>
                    </div>
                    <div className='attachments'>
                        <h5>Attachments</h5>
                        <div className='allAttachments d-flex justify-content-between'>
                            {[1, 2, 3].map((index) => (
                                <div className="single_attachment" style={{ textAlign: 'center'}} key={index}>
                                    <img src={image1} alt={image1} width="72px" height="72px" />
                                    <p className='pdf_link'>Pump maintenance SOP.pdf</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}
