import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { TextField } from '@mui/material';
import { Button } from 'reactstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { createBankDetails } from '../../api/vacant_request';
import { AsterikLabel } from '../Common/AsterikLabel';
import ConfirmationModal from '../Common/ConfirmationModal';
import { ACTIONS } from '../../common/constant';
import { Row } from 'reactstrap';

export default function AddBankDetails({ open, onClose, tenant_id, refresh }) {
    const [isConfirmModal, setIsConfirmModal] = useState(false);
    const handleOpenConfimationModal = () => setIsConfirmModal(true);
    const handleCloseConfimationModal = () => setIsConfirmModal(false);
    const [isModal, setIsModal] = useState(true);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            leaving_date: "",
            leave_reason: ''
        },
        validateOnMount: true,
        validationSchema: Yup.object({
            account_holder_name: Yup.string()
                .strict(true)
                .trim('Must not include leading and trailing spaces')
                .max(100, 'Too Long!')
                .required('Please enter account holder name'),
            account_number: Yup.string()
                .matches(/^\d{9,18}$/, 'Invalid account number')
                .required('Please enter account number'),
            bank_name: Yup.string()
                .strict(true)
                .trim('Must not include leading and trailing spaces')
                .max(100, 'Too Long!')
                .required('Please enter bank name'),
            ifsc: Yup.string()
                .strict(true)
                .trim('Must not include leading and trailing spaces')
                .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, 'Invalid IFSC code')
                .required('Please enter ifsc code'),
        }),
    });

    const vacantRequestCreateForTenant = async () => {
        try {
            const payload = {
                tenant_id: tenant_id,
                account_holder_name: formik.values.account_holder_name,
                account_number: formik.values.account_number,
                ifsc: formik.values.ifsc,
                bank_name: formik.values.bank_name,
            }
            const response = await createBankDetails(payload)
            if (response.status === 200) {
                toast.success(response.data.message);
                refresh()
                onClose()
            } else {
                toast.error(response.data.message)
            }
        } catch (err) {
            toast.error(err.response.data.message);
        }
    }

    return (
        <React.Fragment>
            {isModal ? (
                <Dialog
                    open={open}
                    onClose={onClose}
                    backdrop='static'
                    disableScrollLock={true}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                        sx: { width: "510px", borderRadius: "10px", overflow: "hidden" },
                    }}
                    className='ticket_detail_modal'
                >
                    <DialogTitle className='ticket_detail_header d-flex justify-content-between'>
                        <div>
                            <small>Add Bank Details</small>
                        </div>
                        <CancelOutlinedIcon onClick={onClose} style={{ cursor: "pointer", marginTop: "5px" }} />
                    </DialogTitle>
                    <DialogContent>
                        <Row>
                            <div className='col-12 mt-4'></div>
                            <div className='col-sm-6 mb-3'>
                                <TextField
                                    size='small'
                                    className='form-control'
                                    type='text'
                                    name='account_holder_name'
                                    label={<div>Account Holder Name <AsterikLabel /></div>}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values?.account_holder_name || ''}
                                />
                                <div className='d-flex mt-1'>
                                    {formik.touched?.account_holder_name && formik.errors?.account_holder_name && (
                                        <span className="text-danger">{formik.errors?.account_holder_name}</span>
                                    )}
                                </div>
                            </div>
                            <div className='col-sm-6 mb-3'>
                                <TextField
                                    size='small'
                                    className='form-control'
                                    type='text'
                                    name='account_number'
                                    label={<div>Account Number <AsterikLabel /></div>}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values?.account_number || ''}
                                />
                                <div className='d-flex mt-1'>
                                    {formik.touched?.account_number && formik.errors?.account_number && (
                                        <span className="text-danger">{formik.errors?.account_number}</span>
                                    )}
                                </div>
                            </div>
                            <div className='col-sm-6 mb-3'>
                                <TextField
                                    size='small'
                                    className='form-control'
                                    type='text'
                                    name='ifsc'
                                    label={<div>IFSC <AsterikLabel /></div>}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values?.ifsc || ''}
                                />
                                <div className='d-flex mt-1'>
                                    {formik.touched?.ifsc && formik.errors?.ifsc && (
                                        <span className="text-danger">{formik.errors?.ifsc}</span>
                                    )}
                                </div>
                            </div>
                            <div className='col-sm-6 mb-3'>
                                <TextField
                                    size='small'
                                    className='form-control'
                                    type='text'
                                    name='bank_name'
                                    label={<div>Bank Name <AsterikLabel /></div>}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values?.bank_name || ''}
                                />
                                <div className='d-flex mt-1'>
                                    {formik.touched?.bank_name && formik.errors?.bank_name && (
                                        <span className="text-danger">{formik.errors?.bank_name}</span>
                                    )}
                                </div>
                            </div>
                        </Row>
                        <div className="gen_leave_add_btn text-center">
                            <Button className="yellow_gradient_btn pay_full_amt" disabled={!formik.isValid} onClick={() => {
                                setIsModal(false)
                                handleOpenConfimationModal()
                            }} style={formik.isValid ? { cursor: 'pointer' } : { cursor: 'not-allowed', backgroundColor: 'gray' }}> Save </Button>
                        </div>
                    </DialogContent>
                </Dialog>
            ) : (
                <ConfirmationModal
                    action={ACTIONS.CREATE}
                    show={isConfirmModal}
                    text="Please check carefully the bank account details before submitting."
                    onCloseClick={() => {
                        setIsModal(true)
                        handleCloseConfimationModal()
                    }}
                    onAcceptClick={vacantRequestCreateForTenant}
                />
            )}
        </React.Fragment>
    );
}
