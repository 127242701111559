import React, { useEffect, useRef, useState } from "react";
import { Card, Container, Col, Row } from "reactstrap";
import {
  CurrencyRupeeOutlined,
  RestaurantMenu, FmdGoodOutlined, KingBedOutlined,
  CalendarMonthOutlined,
  Info,
  CloseOutlined
} from "@mui/icons-material";
import Button from "@mui/material/Button";
import {
  Payment,
  getList,
  getTenantDetails,
  rentSlipData,
} from "../../api/tenant";
import { useProfile } from "../../components/Hooks/UserHooks";
import { Skeleton, Popover, Typography, Modal, Box } from "@mui/material";
import AddressComponent from "../../components/Common/Address";
import { BED_TYPES } from "../../common/constant";
import dayjs from "dayjs";
import Variants from "../../components/Common/SkeletonLoader";
import moment from "moment";
import { toast } from "react-toastify";
import PropertyRating from "../../components/Property/PropertyRating";
import { getS3BaseUrl } from "../../helpers/string_helper";
import { NoDataFound } from "../../components/Common/NoDataFound";
import { ProductSlider } from "../../components/Dashboard/propertySlider";
import NoDataIcon from "../../assets/images/no-data.png"

const amenitiesStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
  borderRadius: '10px',
  '@media screen and (max-width: 768px)': {
    width: 'calc(100% - 20px)',
    maxWidth: 360,
  }
};

export default function TenantDashboard() {
  const [tenantDetailsData, setTenantDetailsData] = useState();
  const [rentDetailList, setRentDetailList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [global, setGlobalValue] = useState([]);
  const { user } = useProfile();
  const [isDocumentModal, setIsDocumentModal] = useState(false);
  const [documents, setDocuments] = useState();
  const handleClose = () => setIsDocumentModal(false);
  const handleOpen = () => setIsDocumentModal(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const s3BaseUrl = getS3BaseUrl();

  const handleClickInfo = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseInfo = () => {
    setAnchorEl(null);
  };
  document.title = " Dashboard | DStayZ";

  useEffect(() => {
    fetchTenantDetails();
    listApi();
    user.id && fetchRentDetails();
  }, []);

  const fetchTenantDetails = async () => {
    try {
      setIsLoading(true);
      const response = (await getTenantDetails(user.id)).data.data;
      if (response.property?.food_menu_documents?.length > 0) {
        const result = response.property?.food_menu_documents?.map((item) => {
          return { ...item, location: `${s3BaseUrl}${item?.location}` }
        })
        setDocuments(result)
      }

      setTenantDetailsData(response);
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const calCulate = useRef(0);

  const listApi = async () => {
    try {
      const response = await getList();
      setGlobalValue(
        response.data.data?.data?.find((e) => e.name === "platform_fee")
      );
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const fetchRentDetails = async () => {
    try {
      setIsLoading(true);
      const response = await rentSlipData(user?.id);
      setRentDetailList(response.data.data);
      const total =
        response?.data?.data?.reduce(
          (curr, acc) => acc?.total_amount + curr,
          0
        ) || 0;
      calCulate.current = total;
      setIsLoading(false);
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  const fetchBedType = (bedCount) => {
    switch (bedCount) {
      case 1:
        return BED_TYPES.SINGLE;
      case 2:
        return BED_TYPES.DOUBLE;
      case 3:
        return BED_TYPES.TRIPLE;
      default:
        return `${bedCount}th Sharing`
    }
  };

  const payBill = async () => {
    const tenant_id = tenantDetailsData?.user?.tenant?.id;
    const payload = {
      rent_details: rentDetailList?.map((rent) => ({
        id: rent?.id || [],
      })),
      total_amount:
        Number(calCulate.current) + Number(global?.payload?.value) || 0,
    };
    try {
      setPaymentLoading(true);
      const pay = await Payment(payload, tenant_id);
      if (pay?.status === 200) {
        toast.success("Rent Paid Successfully");
        fetchRentDetails();
      }
    } catch (err) {
      toast.error(err.response.data.message || err.message);
    } finally {
      setPaymentLoading(false);
    }
  };

  return (
    <div className="page-content">
      <Container fluid>
        {(!tenantDetailsData?.property && !isLoading) && (
          <div style={{ textAlign: "center" }}>
            <NoDataFound text="No property added to this tenant" icon={NoDataIcon} />
          </div>)}
        {isLoading && !tenantDetailsData?.property && (
          <Col md={12}>
            <Row>
              <Col md={12}>
                <Skeleton
                  variant="rounded"
                  className="p-2 m-2 h-75"
                ></Skeleton>
              </Col>
              <Col md={12}>
                <Skeleton
                  variant="rounded"
                  className="p-2 m-2 h-75"
                ></Skeleton>
              </Col>
              <Col md={12}>
                <Skeleton
                  variant="rounded"
                  className="p-2 m-2 h-75"
                ></Skeleton>
              </Col>
            </Row>
          </Col>
        )}
        {tenantDetailsData?.property && (
          <div>
            <Card>
              <div className="tenant_dashboard">
                <div className="appartment_details d-grid two_main_grid_cols">
                  <div className="d-grid two_grid_cols single_item">
                    <div className="appartment_img mx-2 ">
                      {tenantDetailsData?.property?.default_image && (
                        <img src={`${s3BaseUrl}${tenantDetailsData?.property?.default_image?.location}`} alt={tenantDetailsData?.property?.default_image?.name} />
                      )}
                    </div>
                    {tenantDetailsData?.property?.id && <div className="">
                      <h5>{tenantDetailsData?.property?.name}</h5>
                      <div>
                        <div className="d-flex">
                          <FmdGoodOutlined />
                          <p className="m-0">
                            <AddressComponent
                              address={tenantDetailsData?.property?.address}
                            />
                          </p>
                        </div>
                        <PropertyRating
                          property_id={tenantDetailsData?.property?.id}
                        />
                        <div className="d-flex ico_content">
                          <div className="locations d-flex">
                            <RestaurantMenu style={tenantDetailsData?.property?.food_menu_documents?.length > 0 ? { marginTop: '2px' } : { marginTop: '1px' }} />
                            <div style={{ minWidth: '70px' }}>{tenantDetailsData?.property?.food_menu_documents?.length > 0 ? (
                              <span>Food Service Available
                                <Info onClick={handleClickInfo} className='ms-1' />
                                <Popover
                                  className='popup_block'
                                  id={tenantDetailsData?.property?.id}
                                  open={open}
                                  anchorEl={anchorEl}
                                  onClose={handleCloseInfo}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  }}
                                >
                                  <Typography sx={{ p: 1 }} className='popContent'>
                                    <p>Food payment should be directly made to the canteen</p>
                                  </Typography>
                                </Popover>
                                <Button onClick={handleOpen}>View Menu Card</Button>
                              </span>
                            ) : <span>Food Service Not Available </span>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>}
                  </div>
                  <div className="d-grid four_grid_cols single_item">
                    <div className="bed_rate child_single_item">
                      <div className="d-flex rounded_icon">
                        <CurrencyRupeeOutlined />
                        <p>Bed Rate</p>
                      </div>
                      <h5>
                        {tenantDetailsData?.user?.bed
                          ? tenantDetailsData?.user?.bed?.price
                          : "N/A"}
                      </h5>
                    </div>
                    <div className="bed_type child_single_item">
                      <div className="d-flex rounded_icon">
                        <KingBedOutlined />
                        <p>Bed Type</p>
                      </div>
                      <h5>
                        {tenantDetailsData?.user?.bed?.room?.room_type?.name}{" "}
                        {fetchBedType(
                          tenantDetailsData?.user?.bed?.room?.bed_count
                        )}
                      </h5>
                    </div>
                    <div className="joining_date child_single_item">
                      <div className="d-flex rounded_icon">
                        <CalendarMonthOutlined />
                        <p>Joining Date</p>
                      </div>
                      <h5>
                        {tenantDetailsData?.joining_date
                          ? dayjs(tenantDetailsData?.joining_date).format(
                            "DD.MM.YYYY"
                          )
                          : "N/A"}
                      </h5>
                    </div>
                    <div className="joining_date child_single_item">
                      <div className="d-flex rounded_icon">
                        <CalendarMonthOutlined />
                        <p>Leaving Date</p>
                      </div>
                      <h5>
                        {tenantDetailsData?.vacant_requests[0]?.leaving_date
                          ? dayjs(
                            tenantDetailsData?.vacant_requests[0]?.leaving_date
                          ).format("DD.MM.YYYY")
                          : "N/A"}
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="due_amt_block d-flex justify-content-between">
                  <div className="d-flex rounded_icon">
                    <CurrencyRupeeOutlined />
                    <div>Total Due Amount</div>
                  </div>
                  <div>₹{Number(calCulate.current) || 0}.00</div>
                </div>
              </div>

            </Card>
            {isLoading ? (
              <div>
                <Variants
                  stackSpacing={3}
                  skeletonData={[
                    ...new Array(3).fill({
                      variant: "rectangle",
                      width: "100%",
                      height: 60,
                    }),
                  ]}
                />
              </div>
            ) : (
              <div className="payment_detail_block">
                <h4 className="block_header">Payment Details</h4>
                <div className="all_payment_blocks">
                    {rentDetailList?.length > 0 && (
                      rentDetailList?.map((bill) => (
                        <Card key={bill?.id}>
                          <div className="d-grid five_grid_cols p-2">
                            <div className="single_payment_item">
                              <span>Month</span>
                              <h5 className="truncate_month">
                                {moment(bill?.start_date).format("MMMM-YYYY")}
                              </h5>
                            </div>
                            <div className="single_payment_item">
                              <span>Rent</span>
                              <h5>₹ {bill?.rent}</h5>
                            </div>
                            <div className="single_payment_item">
                              <span>Electricity</span>
                              <h5>₹ {bill?.electricity_amount}</h5>
                            </div>
                            <div className="single_payment_item">
                              <span>Other Charges</span>
                              <h5>₹ {bill?.other_amount}</h5>
                            </div>
                            <div className="single_payment_item">
                              <span>Total</span>
                              <h5>₹ {bill?.total_amount}</h5>
                            </div>
                          </div>
                        </Card>
                      ))
                    )}
                    {rentDetailList?.length ? (
                      <Card className="total_payment p-0">
                        <ul className="p-3 mb-0">
                          <li className="d-flex justify-content-between">
                            <span className="darker_text">Sub Total</span>
                            <h5 className="darker_text">
                              ₹{calCulate.current}
                              .00
                            </h5>
                          </li>
                          <li
                            className="d-flex justify-content-between "
                            style={{ margin: "10px 0" }}
                          >
                            <span>Platform fee</span>
                            <h5 className="darker_text">
                              ₹{Number(global?.payload?.value) || 0}.00
                            </h5>
                          </li>
                          <li className="d-flex justify-content-between main_total">
                            <span className="darker_text">Total</span>
                            <h5 className="darker_text">
                              ₹
                              {Number(calCulate.current) +
                                Number(global?.payload?.value) || 0}
                              .00
                            </h5>
                          </li>
                        </ul>
                        <div className="d-flex justify-content-center p-2">
                          <Button
                            onClick={payBill}
                            disabled={paymentLoading}
                            className="yellow_gradient_btn pay_full_amt"
                          >
                            {paymentLoading ? "Processing..." : "Pay Full Amount"}
                          </Button>
                        </div>
                      </Card>
                    ) : (
                      <div style={{ textAlign: "center" }}>
                          <NoDataFound text="No rent payment pending to show" style={{ width: '150px', height: '150px' }} icon={NoDataIcon} />
                      </div>
                    )}
                  </div>
                  {isDocumentModal && (
                    <Modal
                      open={isDocumentModal}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={amenitiesStyle} className="tenantRemove resp_tenant_modal">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="crossIcon cursor-pointer position-absolute">
                            <CloseOutlined onClick={handleClose} />
                          </div>
                        </div>
                        <div className="amenityImagesSlider">
                          <ProductSlider files={documents} />
                        </div>
                      </Box>
                    </Modal>
                  )}
              </div>
            )}
          </div>
        )}

      </Container>
    </div>
  );
}
