export const PER_PAGE = [5, 10, 20, 50, 100];

export const DEFAULT_PER_PAGE_VALUE = 10;

export const DEFAULT_DATE_FORMAT = 'DD-MM-YYYY'

export const DEFAULT_DATETIME_FORMAT = 'DD-MM-YYYY hh:mm A'

export const PORTAL = {
    TENANT_PORTAL: 'Tenant Portal',
    BACKOFFICE_PORTAL: 'Backoffice Portal',
    OWNER_PORTAL: 'Owner Portal',
};
export const PAYMENT_STATUS = {
    PAID:'Paid',
    PENDING: 'Pending',
    DUE: 'Due',
    PROCESSING: 'Processing',
    DRAFT: 'Draft',
    PUBLISHED: 'Published'
}
export const BED_TYPES = {
    SINGLE: 'Single Sharing',
    DOUBLE: 'Double Sharing',
    TRIPLE: 'Triple Sharing',
}

export const VACANT_REQUEST_ACKNOWLEDGE_STATUS = {
    PENDING: 'Pending', // default
    ACKNOWLEDGED: 'Acknowledged',
    CANCELLED: 'Cancelled',
};

export const ACTIONS = {
    CREATE: 'Create',
    EDIT: 'Edit',
    VIEW: 'View',
    DELETE: 'Delete',
};

export const KNOWN_ROLES = {
    SUPER_ADMIN: 'Super Admin',
    OWNER: 'Owner',
    TENANT: 'Tenant',
    PROPERTY_MANAGER: 'Property Manager',
    PROSPECTING_OWNER: 'Prospecting Owner',
}

export const PROPERTY_TYPES = {
    APARTMENT: 'Apartment',
    PERSONAL_HOME: 'Personal Home',
};

export const ALL_MODULES = {
    TENANTS: 'Tenants',
    OWNERS: 'Owners',
    PROPERTIES: 'Properties',
    COMPLAIN_TICKETS: 'Complain Tickets',
    ADD_PROPERTY_REQUESTS: 'Add Property Requests',
    MESSAGE: 'Message',
    NOTIFICATION: 'Notification',
  }


export const OPERATION_TYPE = {
    CREATE: 'Create',
    UPDATE: 'Edit',
    DELETE: 'Delete',
    READ: 'Read',
    ADD_TENANT: 'Add Tenant',
    REMOVE_TENANT: 'Remove Tenant',
  }
